/**
 * Created by ddxfc on 2017/4/8.
 */
export class Typetag {
    id: string;
    type: string;
    name: string;
    kw_text: string;
    date: number;
    count: number;
    time: 'string';

    constructor() {

    }
}
