<div class="text-inturn ease" [@flyInOut]="'in'">
  <div class="title">
    <p>一个刚走出来的前端工程师，一个喜欢梁静茹的前端工程师，一个名叫Fishelly的前端工程师。</p>
  </div>
  <div class="content">
    <h1 class="current">Hello <span>World</span> !</h1>
    <h1>耐得住<span>寂寞</span>;</h1>
    <h1>经得起<span>诱惑</span>;</h1>
    <h1>受得起<span>挫折</span>.</h1>
    <h1>彩虹总在<span>风雨</span>后.</h1>
  </div>
  <div class="footer">
    <p>
      This is a front-end technical website.
    </p>
  </div>
</div>
