<div class="blog-container" >

    <div class="blog-content">
        <a class="blog-item ease" routerLink="/article/{{post.id}}" *ngFor="let post of postList">
            <div class="blog-item-content">
                <div class="blog-image ">
                    <img class="ease" src="{{post.coverSrc}}" alt="">
                    <div class="title"><h3>{{post.abstract}}</h3></div>
                    <div class="frame-mark ease">
                        <p>阅读全文</p>
                    </div>
                </div>
                <div class="blog-msg">
                    <h2 class="blog-title">{{post.title}}</h2>
                    <h5 class="blog-date">{{post.date * 1000 | date:"yyyy-MM-dd HH:mm" }}</h5>
                    <div class="split"></div>
                </div>
                <div class="blog-footer">
                    <div><i class="icon-font">&#xe65d;</i>&nbsp;<span
                        class="ng-binding">{{post.commentCount}}</span></div>
                    <div><i class="icon-font">&#xe6ab;</i>&nbsp;<span class="ng-binding">{{post.readCount}}</span>
                    </div>
                </div>
            </div>
        </a>
    </div>

    <div class="loading" *ngIf="hide">
        <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>

    </div>

</div>
