/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/my-header/my-header.component.ngfactory";
import * as i3 from "./components/my-header/my-header.component";
import * as i4 from "./components/my-image-inturn/my-image-inturn.component.ngfactory";
import * as i5 from "./components/my-image-inturn/my-image-inturn.component";
import * as i6 from "./shared-service/utils/storage.service";
import * as i7 from "@angular/router";
import * as i8 from "./app.component";
import * as i9 from "./shared-service/eventBus/event-bus.service";
import * as i10 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.15s" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "0.15s" }], options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-my-header", [], null, null, null, i2.View_MyHeaderComponent_0, i2.RenderType_MyHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.MyHeaderComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-my-image-inturn", [], null, null, null, i4.View_MyImageInturnComponent_0, i4.RenderType_MyImageInturnComponent)), i1.ɵdid(3, 114688, null, 0, i5.MyImageInturnComponent, [i6.StorageService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], [[24, "@flyInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, [["o", 4]], 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).isActivated ? i1.ɵnov(_v, 6).activatedRoute : ""); _ck(_v, 4, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i8.AppComponent, [i9.EventBusService, i6.StorageService, i10.Meta, i10.Title, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
