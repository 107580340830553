var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CommonService } from './common.service';
import { Comment } from '../../models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommentService = /** @class */ (function (_super) {
    __extends(CommentService, _super);
    function CommentService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CommentService.prototype.createUrl = function (post, url) {
        url = url || this.apiURL.commentResFul;
        return "" + url + post.id;
    };
    CommentService.prototype.getList = function (id) {
        return this.hc.get(this.createUrl({ id: id }) + "/all");
    };
    CommentService.prototype.postComment = function (comment) {
        return this.hc.post(this.apiURL.commentResFul, comment);
    };
    CommentService.ngInjectableDef = i0.defineInjectable({ factory: function CommentService_Factory() { return new CommentService(i0.inject(i1.HttpClient), i0.inject("koaCtx", 8)); }, token: CommentService, providedIn: "root" });
    return CommentService;
}(CommonService));
export { CommentService };
