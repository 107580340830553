/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./my-header.component";
var styles_MyHeaderComponent = [i0.styles];
var RenderType_MyHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyHeaderComponent, data: {} });
export { RenderType_MyHeaderComponent as RenderType_MyHeaderComponent };
export function View_MyHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Fishelly Idx. "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "mobile-menu-button mobile-menu-button-light lines-button x2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "lines"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "nav", [["class", "header-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["routerLink", "/index"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u9996\u9875"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["routerLink", "/article"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u6587\u7AE0"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["routerLink", "/typetag"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u5206\u7C7B\u4E0E\u6807\u7B7E"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "/about"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u5173\u4E8E"])), (_l()(), i1.ɵeld(18, 0, null, null, 17, "div", [["class", "dropdown-menu-m ease"], ["id", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 16, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "a", [["routerLink", "/index"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u9996\u9875"])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 2, "a", [["routerLink", "/article"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 26).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u6587\u7AE0"])), (_l()(), i1.ɵeld(28, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 2, "a", [["routerLink", "/typetag"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(30, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u5206\u7C7B\u4E0E\u6807\u7B7E"])), (_l()(), i1.ɵeld(32, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 2, "a", [["routerLink", "/about"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 34).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(34, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u5173\u4E8E"]))], function (_ck, _v) { var currVal_2 = "/index"; _ck(_v, 6, 0, currVal_2); var currVal_5 = "/article"; _ck(_v, 9, 0, currVal_5); var currVal_8 = "/typetag"; _ck(_v, 13, 0, currVal_8); var currVal_11 = "/about"; _ck(_v, 16, 0, currVal_11); var currVal_14 = "/index"; _ck(_v, 22, 0, currVal_14); var currVal_17 = "/article"; _ck(_v, 26, 0, currVal_17); var currVal_20 = "/typetag"; _ck(_v, 30, 0, currVal_20); var currVal_23 = "/about"; _ck(_v, 34, 0, currVal_23); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 9).target; var currVal_4 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 13).target; var currVal_7 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 16).target; var currVal_10 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 22).target; var currVal_13 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_12, currVal_13); var currVal_15 = i1.ɵnov(_v, 26).target; var currVal_16 = i1.ɵnov(_v, 26).href; _ck(_v, 25, 0, currVal_15, currVal_16); var currVal_18 = i1.ɵnov(_v, 30).target; var currVal_19 = i1.ɵnov(_v, 30).href; _ck(_v, 29, 0, currVal_18, currVal_19); var currVal_21 = i1.ɵnov(_v, 34).target; var currVal_22 = i1.ɵnov(_v, 34).href; _ck(_v, 33, 0, currVal_21, currVal_22); }); }
export function View_MyHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-my-header", [], null, null, null, View_MyHeaderComponent_0, RenderType_MyHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.MyHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyHeaderComponentNgFactory = i1.ɵccf("app-my-header", i4.MyHeaderComponent, View_MyHeaderComponent_Host_0, {}, {}, []);
export { MyHeaderComponentNgFactory as MyHeaderComponentNgFactory };
