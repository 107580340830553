/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./index.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/my-text-inturn/my-text-inturn.component.ngfactory";
import * as i3 from "../../components/my-text-inturn/my-text-inturn.component";
import * as i4 from "../../shared-service/utils/storage.service";
import * as i5 from "./index.component";
import * as i6 from "../../shared-service/eventBus/event-bus.service";
var styles_IndexComponent = [i0.styles];
var RenderType_IndexComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IndexComponent, data: {} });
export { RenderType_IndexComponent as RenderType_IndexComponent };
export function View_IndexComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-my-text-inturn", [], null, null, null, i2.View_MyTextInturnComponent_0, i2.RenderType_MyTextInturnComponent)), i1.ɵdid(1, 245760, null, 0, i3.MyTextInturnComponent, [i4.StorageService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A92016-\u81F3\u4ECA All Rights Reserved. Designed by Fishelly. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "http://www.beian.miit.gov.cn"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u7CA4ICP\u590717038119\u53F7"])), (_l()(), i1.ɵted(-1, null, [". "]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_IndexComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-index", [], null, null, null, View_IndexComponent_0, RenderType_IndexComponent)), i1.ɵdid(1, 114688, null, 0, i5.IndexComponent, [i6.EventBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IndexComponentNgFactory = i1.ɵccf("app-index", i5.IndexComponent, View_IndexComponent_Host_0, {}, {}, []);
export { IndexComponentNgFactory as IndexComponentNgFactory };
