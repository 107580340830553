var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CommonService } from './common.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PostService = /** @class */ (function (_super) {
    __extends(PostService, _super);
    function PostService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PostService.prototype.createUrl = function (post, url) {
        url = url || this.apiURL.articleResFul;
        return "" + url + post.id;
    };
    PostService.prototype.getListByTypeTag = function (data) {
        return this.hc.get(this.apiURL.articleResFul + "typetag", {
            params: data
        });
    };
    PostService.prototype.getList = function (data) {
        return this.hc.get(this.apiURL.articleResFul, {
            params: data
        });
    };
    PostService.prototype.getPost = function (id) {
        return this.hc.get(this.createUrl({ id: id }));
    };
    PostService.prototype.increment = function (id) {
        return this.hc.put(this.createUrl({ id: id }) + "/increment", {});
    };
    PostService.ngInjectableDef = i0.defineInjectable({ factory: function PostService_Factory() { return new PostService(i0.inject(i1.HttpClient), i0.inject("koaCtx", 8)); }, token: PostService, providedIn: "root" });
    return PostService;
}(CommonService));
export { PostService };
