import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { util } from '../utils/normal';
import * as i0 from "@angular/core";
/**
 * 事件总线，组件之间可以通过这个服务进行通讯
 */
var EventBusService = /** @class */ (function () {
    function EventBusService() {
        this.bus = new Subject();
        this.bus$ = this.bus.asObservable();
        this._map = {};
    }
    EventBusService.prototype.emit = function (name, data) {
        var event = new Event(name, data);
        this.bus.next(event);
    };
    EventBusService.prototype.on = function (name, func) {
        var _id = util.randomString();
        this._map[name] = this._map[name] ? this._map[name] : {};
        this._map[name][_id] = this.bus$.pipe(filter(function (event) { return event.type === name; }))
            .subscribe(func);
        return _id;
    };
    EventBusService.prototype.off = function (name, id) {
        var subArray = this._map[name] || {};
        if (id) {
            var subItem = subArray[id];
            subItem.unsubscribe();
            delete this._map[name][id];
        }
        else {
            Object.values(subArray).forEach(function (val) {
                val.unsubscribe();
            });
            delete this._map[name];
        }
    };
    EventBusService.ngInjectableDef = i0.defineInjectable({ factory: function EventBusService_Factory() { return new EventBusService(); }, token: EventBusService, providedIn: "root" });
    return EventBusService;
}());
export { EventBusService };
var Event = /** @class */ (function () {
    function Event(type, data) {
        this.type = type;
        this.data = data;
    }
    return Event;
}());
export { Event };
