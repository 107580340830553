import { util } from './normal';
var MyStorage = /** @class */ (function () {
    function MyStorage() {
        this._map = {};
    }
    MyStorage.prototype.setItem = function (key, value) {
        this._map[key] = value;
    };
    MyStorage.prototype.getItem = function (key) {
        return this._map[key];
    };
    MyStorage.prototype.removeItem = function (key) {
        delete this._map[key];
    };
    MyStorage.prototype.clear = function () {
        this._map = {};
    };
    Object.defineProperty(MyStorage.prototype, "length", {
        get: function () {
            return Object.keys(this._map).length;
        },
        enumerable: true,
        configurable: true
    });
    MyStorage.prototype.key = function (num) {
        return util.randomString();
    };
    return MyStorage;
}());
export { MyStorage };
