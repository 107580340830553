import { MyStorage } from './my-storage';
import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService() {
        this.myStorage = new MyStorage();
    }
    StorageService.check = function (val) {
        return !(typeof val === 'undefined' || typeof val === 'function') && val;
    };
    StorageService.prototype.create = function (type) {
        if (!window.localStorage) {
            this.storage = this.myStorage;
        }
        else if (type) {
            this.storage = window.localStorage;
        }
        else {
            this.storage = window.sessionStorage;
        }
        return this;
    };
    StorageService.prototype.setItem = function (key, val) {
        if (StorageService.check(val) && StorageService.check(key)) {
            this.storage.setItem(key, JSON.stringify({ data: val }));
        }
    };
    StorageService.prototype.getItem = function (key) {
        if (StorageService.check(key)) {
            var val = this.storage.getItem(key);
            if (StorageService.check(val)) {
                return JSON.parse(val).data;
            }
            else {
                return null;
            }
        }
    };
    StorageService.prototype.removeItem = function (key) {
        if (StorageService.check(key)) {
            this.storage.removeItem(key);
        }
    };
    StorageService.prototype.clear = function () {
        this.storage.clear();
    };
    Object.defineProperty(StorageService.prototype, "length", {
        get: function () {
            return this.storage.length;
        },
        enumerable: true,
        configurable: true
    });
    StorageService.prototype.key = function (num) {
        this.storage.key(num);
    };
    StorageService.ngInjectableDef = i0.defineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
