import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommonService = /** @class */ (function () {
    function CommonService(hc, ctx) {
        this.hc = hc;
        this.ctx = ctx;
        this.apiURL = {};
        var apiUrl = JSON.parse(JSON.stringify(window.environment.apiURL));
        if (this.ctx) {
            var url_1 = this.ctx.request.origin;
            Object.keys(apiUrl).forEach(function (key) {
                apiUrl[key] = "" + url_1 + apiUrl[key];
            });
        }
        this.apiURL = apiUrl;
    }
    CommonService.ngInjectableDef = i0.defineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.inject(i1.HttpClient), i0.inject("koaCtx", 8)); }, token: CommonService, providedIn: "root" });
    return CommonService;
}());
export { CommonService };
