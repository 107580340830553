/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my-image-inturn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./my-image-inturn.component";
import * as i3 from "../../shared-service/utils/storage.service";
var styles_MyImageInturnComponent = [i0.styles];
var RenderType_MyImageInturnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyImageInturnComponent, data: {} });
export { RenderType_MyImageInturnComponent as RenderType_MyImageInturnComponent };
export function View_MyImageInturnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "image-inturn"]], null, null, null, null, null))], null, null); }
export function View_MyImageInturnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-my-image-inturn", [], null, null, null, View_MyImageInturnComponent_0, RenderType_MyImageInturnComponent)), i1.ɵdid(1, 114688, null, 0, i2.MyImageInturnComponent, [i3.StorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyImageInturnComponentNgFactory = i1.ɵccf("app-my-image-inturn", i2.MyImageInturnComponent, View_MyImageInturnComponent_Host_0, {}, {}, []);
export { MyImageInturnComponentNgFactory as MyImageInturnComponentNgFactory };
