/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./article-detail.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../shared-service/eventBus/event-bus.service";
import * as i6 from "../../shared-service/utils/storage.service";
import * as i7 from "../../shared-service/model/post.service";
import * as i8 from "@angular/router";
var styles_ArticleDetailComponent = [i0.styles];
var RenderType_ArticleDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArticleDetailComponent, data: {} });
export { RenderType_ArticleDetailComponent as RenderType_ArticleDetailComponent };
function View_ArticleDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "tag-item ease"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoTypetag(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_ArticleDetailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "article-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "article-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "article-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", "\u00A0\u00A0|\u00A0\u00A0\u5206\u7C7B\uFF1A"])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "type-status"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoTypetag(_co.article) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0|\u00A0\u00A0\u6807\u7B7E\uFF1A "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleDetailComponent_1)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [["class", "abstract"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["\u6458\u8981\uFF1A", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "article-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "col-md-offset-2 col-md-8 content"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.article.tag; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.article.coverSrc; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.article.title; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), (_co.article.date * 1000), "yyyy-MM-dd HH:mm")); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.article.type.name; _ck(_v, 11, 0, currVal_3); var currVal_5 = _co.article.abstract; _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.article.articleHtml; _ck(_v, 18, 0, currVal_6); }); }
export function View_ArticleDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-article-detail", [], null, null, null, View_ArticleDetailComponent_0, RenderType_ArticleDetailComponent)), i1.ɵdid(1, 4308992, null, 0, i3.ArticleDetailComponent, [i4.TransferState, i5.EventBusService, i6.StorageService, i7.PostService, i8.Router, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArticleDetailComponentNgFactory = i1.ɵccf("app-article-detail", i3.ArticleDetailComponent, View_ArticleDetailComponent_Host_0, {}, {}, []);
export { ArticleDetailComponentNgFactory as ArticleDetailComponentNgFactory };
